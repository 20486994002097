<template>
  <div>
    <input type="text"
           v-if="edit"
           :value="valueLocal"
           @blur="doneEditing"
           @keyup.enter="blurElement($event.target)"
           @keyup.esc="cancelEditing"
           v-focus=""
             />
        <span v-else @click="edit = true;">
          {{valueLocal}} <i class="fa fa-pencil gray hover-gold pl1"></i>
        </span>
  </div>
</template>

<script>
// usage:
//  From parent component, insert something like the following:
//   <ClickToEdit v-bind:value="this.test" valueAuxData="1", eventToEmit="notifyMeEventName" />
//   <ClickToEdit value="hello" valueAuxData="2"/>
//  This component will emit the event you ask for, and the message will be
//  an object with the valueAuxData and new value of the edited field.

import { messageBus } from "@/main.js";

export default {
  //props: ['value'],
  props: {
    value: {
      // initial value of the edit dialog
      //type: String,
      required: true
    },
    valueAuxData: {
      // unique identifier for this edit field. This ID will be
      // emitted by event when the prop 'value' changes
      required: false
    },
    eventToEmit: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      edit: false,
      valueLocal: this.value
    };
  },
  methods: {
    created: function() {
      //console.log("^^^^^ CREATED ^^^^^^")
    },
    updated: function() {
      //console.log("Quack like a duck")
    },
    doneEditing($event) {
      if (!this.edit) {
        return;
      }

      // remove white space first from both values
      const newVal = $event.target.value.trim();
      const oldVal = this.valueLocal.trim();

      // check if value changed. If yes, notify if the parent gave us an event
      if (newVal != oldVal) {
        console.log(
          "value with auxData " +
            this.valueAuxData +
            " changed to: " +
            $event.target.value
        );
        if (this.eventToEmit) {
          messageBus.$emit(this.eventToEmit, {
            valueAuxData: this.valueAuxData,
            value: newVal
          });
        }
      }

      // Remember new value, then set editing to false
      this.valueLocal = $event.target.value.trim();
      this.edit = false;
      this.$emit("input", this.valueLocal.trim());
      console.log("done editing field");
    },
    cancelEditing() {
      // do nothing, essentially.  OK to trim the value though.
      this.edit = false;
      this.$emit("input", this.valueLocal.trim());
      console.log("done editing field -- edit cancelled");
    },
    blurElement(el) {
      el.blur();
    }
  },
  watch: {
    value: function() {
      // can change function parameters to accept (newVal, oldVal)
      //console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.valueLocal = this.value;
    }
  },

  directives: {
    focus: {
      inserted(el) {
        el.focus();
      }
    }
  }
};
</script>
