<template>
  <div class="jsonviewer" :style="cssVars">
    <div @click="showRawJson = !showRawJson" class="blue f5 fw5 pointer">
      <i v-if="!showRawJson" class="f5 gray fa fa-caret-right mr1" />
      <i v-else class="f5 gray fa fa-caret-down mr1" />
      <slot name="name">
        <span class="underline-hover">{{ this.label }}</span>
      </slot>
    </div>

    <!-- <h4 class="f3 fw5 tracked" @click="showRawJson = !showRawJson">Raw JSON</h4> -->
    <div class="table-responsive table--no-card m-b-40">
      <vue-json-pretty :data="json" v-if="showRawJson"> </vue-json-pretty>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Helpers from "@/helpers.js";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default {
  name: "JSONViewer",
  data() {
    return {
      showRawJson: false,
    };
  },
  props: {
    json: Object,
    label: {
      type: String,
      required: false,
      default: "Raw JSON",
    },
    dataFontSize: {
      type: Number,
      required: false,
      default: 14,
    },
    expanded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    cssVars() {
      return {
        "--datafont-size": this.dataFontSize + "px",
      };
    },
  },
  methods: {
    isAdmin() {
      return Helpers.isAdmin();
    },
  },
  created: function () {
    this.showRawJson = this.expanded;
  },
  mounted: function () {},
  components: { VueJsonPretty },
};
</script>

<style>
.vjs-tree {
  font-family: Monaco, Menlo, Consolas, Bitstream Vera Sans Mono, monospace;
  font-size: var(--datafont-size);
}
</style>
